import React, { createContext, FC, useContext } from "react";
import Api from "../public/axios";


const AuthContext = createContext(null);

export const useLoginData:any = ()=>{

    const contenxt = useContext(AuthContext);

    return contenxt;

}

type AuthProiverProps = {
    data:any,
    children:any
}

const AuthProiver:FC<AuthProiverProps> =  ({children,data})=>{
        return (
            <AuthContext.Provider value={data}>
                {children}
            </AuthContext.Provider>
        )

}


export default AuthProiver;