import React from 'react';
import {
    UsergroupAddOutlined,
    GroupOutlined,
    ShopOutlined,
    RobotOutlined,
    UserOutlined,
    SettingOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    OrderedListOutlined,
    GatewayOutlined,
    WalletOutlined,
    MoneyCollectOutlined,
    LineHeightOutlined,
    ClusterOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
    BookOutlined,
    EnvironmentOutlined,
    BankOutlined,
    FormOutlined,
    MessageOutlined,
    BugOutlined,
    CodeOutlined,
    AuditOutlined,
    TeamOutlined,
    BookTwoTone,
    FileOutlined,
    FundOutlined
    
} from '@ant-design/icons';


export interface MenuData {
    path: string;
    name: string;
    key: string;
    perms:string,
    icon: any | null | undefined;
    children: Array<MenuData> | null | undefined
};



export const icons:any = {
    "home":HomeOutlined,
    "sys":SettingOutlined,
    "shop":ShopOutlined,
    "goods":ShoppingCartOutlined,
    "coach":UserOutlined,
    "order":OrderedListOutlined,
    "game":GatewayOutlined,
    "wallet":WalletOutlined,
    "robot":RobotOutlined,
    "withdraw":MoneyCollectOutlined,
    "statis":LineHeightOutlined,
    "course":ClusterOutlined,
    "log":UnorderedListOutlined,
    "member":UsergroupAddOutlined,
    "minapp":AppstoreOutlined,
    "package":BookOutlined,
    "actionlib":EnvironmentOutlined,
    "coupon":BankOutlined,
    "form":FormOutlined,
    "message":MessageOutlined,
    "feedback":BugOutlined,
    "invite":CodeOutlined,
    "detect":AuditOutlined,
    "group":TeamOutlined,
    "report":FileOutlined,
    "train:data":FundOutlined
}

export default {};


const forEachMenuAll = (menus: Array<MenuData>, fn: Function) => {
    for (var i = 0; i < menus.length; i++) {
        const x = menus[i];
        const eachLast = (i == menus.length - 1);
        const isLast = x.children ? false : true;
        const next = fn(x, isLast, eachLast);
        if (!next) {
            return false;
        }
        if (x.children) {
            if (!forEachMenuAll(x.children, fn)) {
                return false;
            }
        }
    }
    return true;

}

const getMenuAll= (menus:MenuData[])=>{

    const all:MenuData[] = [];

    menus.forEach(m=>{
        all.push({
            ...m,
            children:undefined
        });

        if(m.children){
            getMenuAll(m.children).forEach(m=>{
                all.push({
                    ...m,
                    children:undefined
                })
            })
        }

    })
    return all;
}


export const getMenuNames = (paths:string[],menus:MenuData[])=>{

    
    return getMenuAll(menus).filter(menu=> paths.find(x=>x == menu.path)).map(m=>m.name);

}

export const getAllPaths = (menus:Array<MenuData>,subPath: string): Array<any> => {

    // console.log("subname",subPath);

    let all: Array<any> = [];
    forEachMenuAll(menus, (route: any, isLast: boolean, eachLast: any) => {
        if (!isLast) {
            all.push(route);
        }
        if (route.path == subPath) {
            all.push(route);
            return false;
        }
        if (isLast && eachLast) {
            all = [];
        }
        return true;

    })
    return all;


}