import { type } from "os";
import React, { createContext, FC, useContext, useState,useReducer } from "react";
import Api from "../public/axios";


const ShopContext = createContext(null);

export const useShop: DataProps | null | any = () => {

    const contenxt: any = useContext(ShopContext);

    return contenxt;

}

type DataProps = {
    currrent: any|null,
    setCurrent: (shop: any) => void
}

type ShopProiverProps = {
    data: DataProps | null | any,
    children:any
}

const ShopProiver: FC<ShopProiverProps> = ({ children,data}) => {

    const state:any = useState<any|null|ShopProiverProps>(data);

    return (
        <ShopContext.Provider value={state}>
            {children}
        </ShopContext.Provider>
    )

}


export default ShopProiver;