import { App, ConfigProvider } from 'antd';
import { type } from 'os';
import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import routes from './config/routes';
import zhCN from 'antd/locale/zh_CN';
import moment from "moment";
import TimezoneProiver from './components/TimezoneProvier';
import "moment/locale/zh-cn";
import { StyleProvider } from '@ant-design/cssinjs';
import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";
import "@ant-design/flowchart/dist/index.css"

dayjs.locale('zh-cn');

moment.locale("zh-cn");

export default () => {


  const renderRoute = (route: any, index: number) => {

    const hasChildren = route.children ? true : false;
    const children = hasChildren ? [...route.children] : [];
    // console.log("props:", route,hasChildren,children);
    return (
      <>
        <Route key={index} path={route.path} index={route.index} element={

          hasChildren ? <route.layout>
            <Routes>
              {children.map((it, i) => {
                return renderRoute(it, i);
              })}
            </Routes>
          </route.layout> : <route.component />
        } >
        </Route>
      </>
    )


  };

  return (
    <>

      <StyleProvider hashPriority="high">
       


          <ConfigProvider locale={zhCN} theme={{
            token: {
              colorPrimary: "#F90007",
            }
          }} >
            <BrowserRouter>
              <Routes>

                {routes.map((it, i) => {
                  return (
                    <>
                      {renderRoute(it, i)}
                    </>
                  )
                })}


              </Routes>
            </BrowserRouter>
          </ConfigProvider>
   
      </StyleProvider>
    </>
  )

};
