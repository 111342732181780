import { message, Modal } from "antd";
import React from "react";
import Api from "./axios";


export const deleteConfirm = (api:Api,onSuccess:Function)=>{

    const modal = Modal.confirm({
        title:"提示",
        content:"是否删除",
        onOk:(close)=>{

            modal.update({
                okButtonProps:{loading:true}
            });
            api.delete({}).then(ret=>{
                if(ret.code == 200){
                    message.success(ret.msg);
                    close();
                    onSuccess();
                }

            }).finally(()=>{

                modal.update({okButtonProps:{loading:false}});
            })

        }

    })

}

type ApiDeleteConfirmAlertConfig = {
    api:Api,
    data?:any,
    title?:string,
    content?:string,
    onSuccess?:()=>void
}

type ApiDeleteConfirmAlertFunc = (config:ApiDeleteConfirmAlertConfig)=>void

/**
 * 删除
 * @param param
 */
export const ApiDeleteConfirmAlertFunc:ApiDeleteConfirmAlertFunc = ({api,title="提示",content="是否删除？",data={},onSuccess=()=>{}})=>{
    const modal = Modal.confirm({
        title:title,
        content:content,
        onOk:(close)=>{

            modal.update({
                okButtonProps:{loading:true}
            });
            api.delete({...data}).then(ret=>{
                if(ret.code == 200){
                    message.success(ret.msg);
                    close();
                    onSuccess();
                }

            }).finally(()=>{

                modal.update({okButtonProps:{loading:false}});
            })

        }

    })
}


type ApiPostConfirmAlertConfig = {
    api:Api,
    data:any,
    title?:string,
    content?:string,
    onSuccess?:()=>void
}

type ApiPostConfirmAlertFunc = (config:ApiPostConfirmAlertConfig)=>void

export const ApiPostConfirmAlert:ApiPostConfirmAlertFunc = ({api,data={},title="提示",content,onSuccess=()=>{}})=>{


    const modal = Modal.confirm({
        title:title,
        content:content,
        onOk:(close)=>{
            modal.update({
                okButtonProps:{loading:true}
            });
            api.post({...data}).then(ret=>{
                if(ret.code == 200){
                    message.success(ret.msg);
                    close();
                    onSuccess();
                }

            }).finally(()=>{

                modal.update({okButtonProps:{loading:false}});
            })

        }

    })


}

