import React from "react";
import { Layout } from "antd";
import AsyncComponent from "../../../components/AsyncComponent";
import EmptyLayout from "../../../components/EmptyLayout";
import ContentLayout from "../../../components/ContentLayout";
import AdminLayout from "../layout/AdminLayout";




export default [
    {
        path: "/login",
        layout: EmptyLayout,
        component: AsyncComponent(() => import("../pages/login"))
    },
    {
        path:"/user/medical/:token",
        component: AsyncComponent(() => import("../pages/medical/index"))
    },
    {
        path:"/user/medical/submit/:token",
        component: AsyncComponent(() => import("../pages/medical/submit"))
    },
    {
        path:"/user/medical/detail",
        component: AsyncComponent(() => import("../pages/medical/detail"))
    },
    {
        path: "/*",
        layout: AdminLayout,
        children: [
            { path: "/", component: AsyncComponent(() => import("../pages/home")) },
            {
                path: "/shop/*",
                layout: ContentLayout,
                children: [
                    { path: "/list", component: AsyncComponent(() => import("../pages/shop/index")) },
                ]
            },
            {
                path: "/course/*",
                layout: ContentLayout,
                children: [
                    { path: "/category", component: AsyncComponent(() => import("../pages/course/category")) },
                    { path: "/type", component: AsyncComponent(() => import("../pages/course/type")) },
                    { path: "/items", component: AsyncComponent(() => import("../pages/course/item")) },
                    { path: "/video", component: AsyncComponent(() => import("../pages/course/video")) },
                    { path: "/theme", component: AsyncComponent(() => import("../pages/course/theme")) },
                    { path: "/schedule", component: AsyncComponent(() => import("../pages/course/schedule")) },
                    { path: "/rmedal", component: AsyncComponent(() => import("../pages/course/rmedal")) }
                ]
            },
            {
                path: "/coach/*",
                layout: ContentLayout, 
                children: [
                    { path: "/users", component: AsyncComponent(() => import("../pages/coach/user")) },
                   
                ]
            },
            {
                path: "/group/*",
                layout: ContentLayout, 
                children: [
                    { path: "/list", component: AsyncComponent(() => import("../pages/group/index")) },
                   
                ]
            },
            {
                path: "/minapp/*",
                layout: ContentLayout, 
                children: [ 
                    { path: "/banner", component: AsyncComponent(() => import("../pages/minapp/banner")) },
                    { path: "/item", component: AsyncComponent(() => import("../pages/minapp/item")) }
                ]
            },
            {
                path: "/minapp/*",
                layout: ContentLayout, 
                children: [ 
                    { path: "/banner", component: AsyncComponent(() => import("../pages/minapp/banner")) },
                    { path: "/item", component: AsyncComponent(() => import("../pages/minapp/item")) }
                ]
            },
            {
                path: "/member/*",
                layout: ContentLayout,
                children: [
                    { path: "/card", component: AsyncComponent(() => import("../pages/member/vipCard")) },
                    { path: "/user", component: AsyncComponent(() => import("../pages/member/index")) },
                    { path: "/sign", component: AsyncComponent(() => import("../pages/member/sign")) },
                    { path: "/invite/records", component: AsyncComponent(() => import("../pages/member/invite")) },
                    { path: "/invite/setting", component: AsyncComponent(() => import("../pages/member/invite-setting")) },
                    { path: "/group", component: AsyncComponent(() => import("../pages/member/myMembers")) },
                ]
            },
            {
                path: "/order/*",
                layout: ContentLayout,
                children: [
                    { path: "/course", component: AsyncComponent(() => import("../pages/order/course")) },
                    { path: "/package", component: AsyncComponent(() => import("../pages/order/package")) },
                    { path: "/recover", component: AsyncComponent(() => import("../pages/order/recover")) },
                ]
            },
            {
                path: "/detect/*",
                layout: ContentLayout,
                children: [
                    { path: "/category", component: AsyncComponent(() => import("../pages/detect/category")) },
                    { path: "/order", component: AsyncComponent(() => import("../pages/detect/order")) }
                ]
            },
            {
                path: "/statis/*",
                layout: ContentLayout,
                children: [
                    { path: "/course/apply", component: AsyncComponent(() => import("../pages/statis/course")) },
                    { path: "/package/order", component: AsyncComponent(() => import("../pages/statis/package")) }
                ]
            },
            {
                path:"/action/library/*",
                layout:ContentLayout,
                children:[
                    {path:"/list",component: AsyncComponent(() => import("../pages/actionlib/index"))}
                ]
                
            },
            {
                path:"/report/*",
                layout:ContentLayout,
                children:[
                    {path:"/basic",component: AsyncComponent(() => import("../pages/report/basic"))}
                ]
                
            },
            {
                path:"/package/*", 
                layout:ContentLayout, 
                children:[
                    {path:"/public",component: AsyncComponent(() => import("../pages/package/public"))},
                    {path:"/group",component: AsyncComponent(() => import("../pages/package/group"))},
                    { path: "/private", component: AsyncComponent(() => import("../pages/package/privatePackage")) },
                    {path:"/shop",component: AsyncComponent(() => import("../pages/package/shop")) }
                ]
            },
            {
                path:"/coupon/*",
                layout:ContentLayout, 
                children:[
                    {path:"/list",component: AsyncComponent(() => import("../pages/coupon/index"))},
                    {path:"/records",component: AsyncComponent(() => import("../pages/coupon/records"))},
                    {path:"/send",component: AsyncComponent(() => import("../pages/coupon/send"))},
                    {path:"/use",component: AsyncComponent(() => import("../pages/coupon/hx"))},
                    {path:"/package",component: AsyncComponent(() => import("../pages/coupon/package"))},
                ]
            },
            {
                path:"/form/*",
                layout:ContentLayout, 
                children:[
                    {path:"/data/list",component: AsyncComponent(() => import("../pages/form/index"))},
                
                ]
            },
            {
                path:"/message/*",
                layout:ContentLayout,
                children:[
                    {path:"/list",component: AsyncComponent(() => import("../pages/message/index"))},
                ]
            },
            {
                path:"/feedback/*",
                layout:ContentLayout,
                children:[
                    {path:"/list",component: AsyncComponent(() => import("../pages/feedback/index"))},
                    {path:"/category",component: AsyncComponent(() => import("../pages/feedback/category"))},
                ]
            },
            {
                path: "/sys/*",
                layout: ContentLayout,
                children: [
                    { path: "/user", component: AsyncComponent(() => import("../pages/sys/user")) },
                    { path: "/menu", component: AsyncComponent(() => import("../pages/sys/menu")) },
                    { path: "/role", component: AsyncComponent(() => import("../pages/sys/role")) },
                   
                ]
            },
            {
                path: "/center/*",
                layout: ContentLayout,
                children: [
                    { path: "/setting/password", component: AsyncComponent(() => import("../pages/center/settingPassword")) },
                   
                   
                ]
            },
            {
                path: "/log/*",
                layout: ContentLayout,
                children: [
                    { path: "/login", component: AsyncComponent(() => import("../pages/log/login")) },
                    { path: "/opt", component: AsyncComponent(() => import("../pages/log/opt")) },
                  
                   
                ]
            }
        ]
    }
]