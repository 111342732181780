import { Layout, Breadcrumb, Dropdown, Menu, Modal, Form, Button } from "antd"
import React, { Component, FC, useState } from "react"
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    DownOutlined,
    LogoutOutlined,
    EnvironmentOutlined,
    SecurityScanOutlined
} from '@ant-design/icons';
import MenuLayout from "./MenuLayout";
import { useLoginData } from "./AuthProvier";
import { useNavigate } from "react-router";
import api from "../api/api";
import { useShop } from "./ShopProvier";
import { ApiPostConfirmAlert } from "../public/alert";


const BaLayout = ({ children }: any) => {


    const [collapsed, setCollapsed] = useState<boolean>(false);

    const [menuNames, setMenuNames] = useState<string[]>([]);

    const user: any = useLoginData();

    // const [checkedShop,setCheckedShop] = useState(user?.shops[0]);

    const [shop, setShop] = useShop();


    const navigate = useNavigate();

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout style={{ height: "100%" }}>
            <Layout.Sider theme="light" trigger={null} collapsible collapsed={collapsed} style={{ overflowY: "auto", borderRight: "1px solid #ddd" }}>
                <div style={{ display: "flex", height: 46, alignItems: "center", }}>
                    <img src={"/img/tabti.png"} style={{ width: 35, marginLeft: 23 }} />
                    {!collapsed ? (
                        <span style={{ marginLeft: 10 }}>泰谱云</span>
                    ) : null}

                </div>
                <MenuLayout onSelectMenus={(names: string[]) => {
                    setMenuNames(names);
                }} />
            </Layout.Sider>
            <Layout style={{ overflowY: "auto" }}>
                <Layout.Header style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #ddd",
                    padding: 0,
                    height: 48,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    lineHeight: "48px"
                }}
                >

                    <div style={{ paddingLeft: 10, fontSize: 20, display: "flex" }}>

                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: toggle,
                        })}

                        <div style={{ paddingLeft: 10 }}>
                            <Breadcrumb className="layout-breadcrumb">
                                {
                                    menuNames.map(name => {
                                        return (
                                            <Breadcrumb.Item key={name}>{name}</Breadcrumb.Item>
                                        )
                                    })
                                }

                            </Breadcrumb>
                        </div>

                    </div>

                    <div style={{ display: "flex", height: 48, alignItems: "center" }}>
                        <div style={{ marginRight: 15 }}>

                            <Dropdown
                                placement="bottomRight"
                                overlay={
                                    <Menu

                                        style={{ padding: 0, border: "1px solid rgba(0,0,0,0.1)" }}

                                    >
                                        {user.shops.map((it: any) => {
                                            if (it.id == shop.id) {
                                                return null;
                                            }
                                            return (
                                                <Menu.Item key={it.id} onClick={() => {
                                                    ApiPostConfirmAlert({
                                                        api: api(`login/shop/toggle?id=${it.id}`),
                                                        title: "提示",
                                                        content: "是否切换门店",
                                                        data: {},
                                                        onSuccess: () => {
                                                            window.location.reload();
                                                        }
                                                    })
                                                }}> {it.name}</Menu.Item>
                                            )
                                        })}
                                    </Menu>

                                }
                            >
                                <Button icon={<EnvironmentOutlined />} type="default" >{shop.name}  <DownOutlined /> </Button>
                            </Dropdown>

                        </div>
                        <div style={{ paddingRight: 15 }}>
                            <Dropdown
                                overlay={<>
                                    <Menu style={{ padding: 0, border: "1px solid rgba(0,0,0,0.1)" }}>
                                        <Menu.Item key="1">
                                            <a
                                                href="/center/setting/password"
                                            >
                                                <SecurityScanOutlined />
                                                <span style={{ marginLeft: 5 }}>{"修改密码"}</span>
                                            </a>
                                        </Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item key="2">
                                            <a
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: "提示",
                                                        content: "是否退出登录",
                                                        onOk: () => {
                                                            api("login/out").post({}).then(ret => {
                                                                if (ret.code == 200) {
                                                                    navigate("/login");
                                                                }
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <LogoutOutlined />
                                                <span style={{ marginLeft: 5 }}>{"退出登录"}</span>
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                </>}
                                placement="bottomRight"
                            >
                                <div>
                                    <span style={{ marginRight: 5, }}>
                                        <UserOutlined style={{ fontSize: 18 }} />
                                        <span style={{ marginLeft: 5 }}>
                                            {user?.userName}
                                        </span>

                                    </span>
                                    <DownOutlined />
                                </div>

                            </Dropdown>
                        </div>
                    </div>



                </Layout.Header>
                <Layout.Content style={{ overflowY: "auto", padding: 0 }}>

                    {children}

                    {/* <Layout.Footer style={{ height: 28, display: "flex", alignItems: "center" }}>
                        @xxx@公司
                    </Layout.Footer> */}

                </Layout.Content>

            </Layout>
        </Layout>
    )

    // }
}

export default BaLayout;