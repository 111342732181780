import React, { Component } from "react";
import { Spin, Layout} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
export default (props:any)=> {



        return (
            <Layout style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", flex: 1, backgroundColor: "#fff" }}>
               
               <Spin indicator={<LoadingOutlined style={{fontSize:32}}/>}></Spin>
                    {props?.children}
            </Layout>
        )
    


}