import React, { FC, useEffect, useState } from "react";
import { Menu } from "antd";
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { getAllPaths, MenuData, icons, getMenuNames } from "../config/menu";
import { Link, useLocation } from "react-router-dom";
import { useLoginData } from "./AuthProvier";




type MenuLayoutProps = {
    // menus?:MenuData[]
    onSelectMenus?:Function
}


const MenuLayout: FC<MenuLayoutProps> = ({ onSelectMenus}) => {

    const user = useLoginData();

    const menus: MenuData[] =  [
        ...user.menus
    ];

    const location = useLocation();

    const [openKeys, setOpenKeys] = useState<Array<string>>([]);

    


    const getPathArr = (paths:string)=>{


        if(paths == "/"){
            return ["/"];
        }

        const pathArrs = [];
        const arrs = paths.split("/");
        let str = "";
        for(let i =0; i < arrs.length;i++){

            if(arrs[i]){
                str+=`/${arrs[i]}`;
                pathArrs.push(`${str}`);
            }
        
        }

        return pathArrs;

    }

    console.log("pathname=>",location.pathname);


    useEffect(() => {


        // const defaultOpenKeys = getAllPaths(menus, location.pathname).map(x => x.path);

        const defaultOpenKeys = getPathArr(location.pathname);

        console.log(defaultOpenKeys);

        setOpenKeys(defaultOpenKeys);

        if(onSelectMenus){
            onSelectMenus(getMenuNames(defaultOpenKeys,menus));
        }

        // console.log("keys=>",getPathArr(location.pathname));

    }, [location.pathname]);





    const rednerMenu = ((menu: any,level:number) => {

        const Icon: any = icons[`${menu.perms}`];
        const MenuIcon: any = Icon ? <Icon /> : null;
        const children: Array<any> = menu.children;
        if (children && children.length) {

            return (
                <Menu.SubMenu title={menu.name} key={menu.path} icon={MenuIcon} onTitleClick={e=>{
                   
                    const key = e.key;
                    const find = openKeys.find(x=>x==key);
                    if(!find){

                        openKeys.push(key);
                    }else{
                        for(let i = 0;i < openKeys.length;i++){
                            if(key == openKeys[i]){
                                openKeys.splice(i,1);
                            }
                        }
                    }

                    setOpenKeys([...openKeys])

                }}>
                    {children.map(it => {
                        return rednerMenu(it,(level+1));
                    })}
                </Menu.SubMenu>
            )
        }

        return (
            <Menu.Item key={menu.path} icon={MenuIcon}>
                <Link to={menu.path}>{menu.name}</Link>
            </Menu.Item>
        )
    })
    return (
        <Menu
            theme="light"
            defaultSelectedKeys={[location.pathname]}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={openKeys}
            onOpenChange={keys=>{

                if(keys.length == 0){
                    setOpenKeys([]);
                }

            }}
          
            
        >
            {menus.map(it => {
                return rednerMenu(it,1);
            })}
        </Menu>
    )
}

export default MenuLayout;