import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import AuthLoading from "../../../components/AuthLoading";
import AuthProiver from "../../../components/AuthProvier";
import BaLayout from "../../../components/BaLayout";
import ShopProiver from "../../../components/ShopProvier";



export default (props: any) => {




    const [data, setData] = useState<any | null>(null);


    useEffect(() => {


        api("login/status").get({}).then(ret => {

            if (ret?.code == 200) {
                setData(ret.data);
            }

        });

    }, []);

    if (!data) {

        return (
            <AuthLoading />
        )
    }

    return (
        <AuthProiver data={data}>
            <ShopProiver data={data?.shop}>
                <BaLayout>{props.children}</BaLayout>
            </ShopProiver>
        </AuthProiver>

    )
}