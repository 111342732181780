import adminRouets from "../modules/admin/config/rouets";




const routeMap:any = {
    "admin":[
        ...adminRouets
    ],
   
    // undefined:[]
}
const mod:any = process.env["REACT_APP_MOD"];
// console.log("mod=>",mod)
const roues = routeMap[mod];

export default [

    ...roues
];