import { message, Modal } from "antd";
import Api from "../public/axios"
import { useLocation } from "react-router";
import moment from "moment-timezone";



const config:any = {
    development: {
        //管理员
        admin: {
            // baseUrl:"//ba-api-integ.ajdx.com.cn"
            baseUrl:"//127.0.0.1:7901"
            // baseUrl:"//ba-api.tabti.tabmedifit.com"
        },
       
    },
    production: {
        //管理员
        admin: {
            baseUrl:"//ba-api.tabti.tabmedifit.com"
        },
       
    }
}

const env = process.env.NODE_ENV;
const mod = process.env["REACT_APP_MOD"];
const baseUrl = config[`${env}`][`${mod}`]["baseUrl"];

export const baseApiUrl:string = baseUrl;


const confirmModel = {
    show: false
}

export default (path: string) => {



    return new Api(baseUrl, path)
        .config(options => {
            options.withCredentials = true;
            options.headers = {
                ...options.headers,
                "LOGIN-TOKEN": `${localStorage.getItem("LOGIN-TOKEN")}`,
            };

        })
        .onResponse(resp => {

            if(resp.headers["content-type"].indexOf("application/json") == -1){
                return;
            }

            const data: any = resp.data;
            if (data?.code == 400 || data?.code == 500) {
                message.error(data?.msg);
            }

            //登录失效
            if (data?.code == 401) {



                if (!confirmModel.show) {

                    confirmModel.show = true;
                    message.error(data?.msg, 1.5, () => {
                        // alert("close")
                        // window.location.href = "/login";
                        window.location.replace("/login");
                    })
                }


                return;
            }
            if (data?.code == 402) {

                // window.location.href = `/login/bind-google-token/${encodeURIComponent(data.msg)}`;

            }

        })
        .onError((err) => {
            message.error(err.message);
        })


}