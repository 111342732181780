import React, { Component } from "react";
import { Layout } from "antd";

export default (props: any) => {




    return (
        // <div
        // // {...props} 

        // style={{
        //     minHeight:"calc(100% - 20px)",
        //     position:"relative",

        //     // ...props.style,
        //     padding:15,
        //     backgroundColor:"#ffff",
        //     margin:"10px 10px",
        //     // height:"100%",
        //     flex:"auto",
        //     // minHeight:"100%"

        // }}
        // >
        //     {props.children}
        // </div>



        <Layout.Content
            style={{
                padding: 15,
                backgroundColor: "#ffff",
                margin: "10px 10px",
                minHeight: "calc(100vh - 68px)",
            }}
        >{props.children}</Layout.Content>

    )


}